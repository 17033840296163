@font-face {
  font-family: 'Alegreya Sans SC';
  src: url('AlegreyaSansSC-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Alegreya Sans SC';
  src: url('AlegreyaSansSC-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Alegreya Sans SC';
  src: url('AlegreyaSansSC-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Alegreya Sans SC';
  src: url('AlegreyaSansSC-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Alegreya Sans SC';
  src: url('AlegreyaSansSC-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Alegreya Sans SC';
  src: url('AlegreyaSansSC-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Alegreya Sans SC';
  src: url('AlegreyaSansSC-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
