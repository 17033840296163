@font-face {
  font-family: 'gwi';
  src: url('../font/gwi.eot?37135395');
  src: url('../font/gwi.eot?37135395#iefix') format('embedded-opentype'),
    url('../font/gwi.woff2?37135395') format('woff2'),
    url('../font/gwi.woff?37135395') format('woff'),
    url('../font/gwi.ttf?37135395') format('truetype'),
    url('../font/gwi.svg?37135395#gwi') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'gwi';
    src: url('../font/gwi.svg?37135395#gwi') format('svg');
  }
}
*/
[class^='gwi_']:before,
[class*=' gwi_']:before {
  font-family: 'gwi', sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.gwi_c-arrow-3:before {
  content: '\e800';
} /* '' */
.gwi_c-time:before {
  content: '\e801';
} /* '' */
.gwi_c-cart-outlined:before {
  content: '\e802';
} /* '' */
.gwi_c-play-circle-fill:before {
  content: '\e803';
} /* '' */
.gwi_c-user-avatar-filled:before {
  content: '\e804';
} /* '' */
.gwi_c-lock-outlined:before {
  content: '\e805';
} /* '' */
.gwi_ok-1:before {
  content: '\e806';
} /* '' */
.gwi_c-youtube-square:before {
  content: '\e807';
} /* '' */
.gwi_c-triangle-alert:before {
  content: '\e808';
} /* '' */
.gwi_c-download-outlined:before {
  content: '\e809';
} /* '' */
.gwi_c-info:before {
  content: '\e80a';
} /* '' */
.gwi_c-exit-outline:before {
  content: '\e80b';
} /* '' */
.gwi_c-user-avatar-filled-alt:before {
  content: '\e80c';
} /* '' */
.gwi_c-coins-line:before {
  content: '\e80d';
} /* '' */
.gwi_c-arrow-4:before {
  content: '\e80e';
} /* '' */
.gwi_c-user-add-outlined:before {
  content: '\e80f';
} /* '' */
.gwi_c-windows:before {
  content: '\e810';
} /* '' */
.gwi_c-vk:before {
  content: '\e811';
} /* '' */
.gwi_email:before {
  content: '\e81f';
} /* '' */
.gwi_c-discord:before {
  content: '\e825';
} /* '' */
.gwi_c-telegram:before {
  content: '\e826';
} /* '' */
.gwi_down-open-2:before {
  content: '\f004';
} /* '' */
.gwi_up-open-1:before {
  content: '\f005';
} /* '' */
.gwi_right-open-1:before {
  content: '\f006';
} /* '' */
.gwi_left-open-1:before {
  content: '\f007';
} /* '' */
.gwi_twitter:before {
  content: '\f099';
} /* '' */
.gwi_facebook:before {
  content: '\f09a';
} /* '' */
.gwi_youtube-squared:before {
  content: '\f166';
} /* '' */
.gwi_youtube:before {
  content: '\f167';
} /* '' */
.gwi_instagram:before {
  content: '\f16d';
} /* '' */
.gwi_skype-1:before {
  content: '\f17e';
} /* '' */
.gwi_vkontakte:before {
  content: '\f189';
} /* '' */
.gwi_facebook-rect:before {
  content: '\f301';
} /* '' */
