:root {
  --min-width: 470px;
  /* background */
  --background-body: #030000;
  /* fonts */
  --font-main: 'Alegreya Sans SC', 'PT Sans', Tahoma, Arial, sans-serif;
  --font-second: 'AlbertusExtraBold', 'Times New Roman', Times, serif;
  --font-ico: 'gwi';
  --font-size-main: 16px;
  /* colors */
  --color-light: #e8d1a6;
  --color-red: rgb(255, 39, 39);
  --color-yellow: #fcfc41;
  --color-orange: #fcd601;
  --color-pink: rgb(255, 117, 140);
  --color-light-blue: rgb(87, 160, 206);
  --color-blue: #398fcd;
  --color-green: #97c647;
  --color-grey: #c5c1c1;
  --color-white: white;
  --color-aqua: #56dcee;
  --color-placeholder: #999999;
  --color-placeholder-hover: transparent;
  /* main colors */
  --color-main: #fff;
  --color-accent: var(--color-orange);
  /* input */
  --input-bg: #fff;
  --input-border: 0;
  --input-border-radius: 0;
  --input-color: #858585;
  --input-font-family: var(--font-main);
  --input-font-size: 1.3rem;
  --input-font-weight: 400;
  --input-padding: 0 1rem;
  --input-disabled-bg: #f9f9f9;
  /* margin */
  --margin: 10px;
  --margin-half: calc(var(--margin) / 2);
  --margin-third: calc(var(--margin) / 3);
}

/* https://Get-Web.site - Верстка сайтов на заказ
*/
main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
  border-color: rgba(0, 0, 0, 0.15);
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

img {
  border-style: none;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

video,
iframe {
  max-width: 100%;
  height: auto;
}

a {
  background-color: transparent;
  color: #f8a61f;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: opacity, color, background-color;
  -o-transition-property: opacity, color, background-color;
  transition-property: opacity, color, background-color;
}

a:hover {
  text-decoration: none;
}

.a-link {
  color: rgb(153, 111, 43);
  text-decoration: none;
}

.a-link:hover {
  color: rgb(214, 148, 41);
}

/* убираем выделение ссылок пунктирной рамкой в FF и IE */
a:active,
a:focus {
  outline: 0;
}

::-webkit-input-placeholder {
  color: #999999;
  color: var(--color-placeholder);
  opacity: 1;
}

::-moz-placeholder {
  color: #999999;
  color: var(--color-placeholder);
  opacity: 1;
}

/* Firefox 19+ */
:-moz-placeholder {
  color: #999999;
  color: var(--color-placeholder);
  opacity: 1;
}

/* Firefox 18- */
:-ms-input-placeholder {
  color: #999999;
  color: var(--color-placeholder);
  opacity: 1;
}

:focus::-webkit-input-placeholder {
  color: transparent;
  color: var(--color-placeholder-hover);
}

:focus::-moz-placeholder {
  color: transparent;
  color: var(--color-placeholder-hover);
}

:focus:-moz-placeholder {
  color: transparent;
  color: var(--color-placeholder-hover);
}

:focus:-ms-input-placeholder {
  color: transparent;
  color: var(--color-placeholder-hover);
}

[type='text'],
[type='email'],
[type='tel'],
[type='url'],
[type='password'],
[type='number'],
[type='search'],
[type='button'],
[type='color'],
[type='time'],
[type='date'],
[type='datetime-local'],
[type='file'],
[type='image'],
[type='month'],
[type='reset'],
[type='week'],
select,
textarea,
.field__container {
  width: 100%;
  height: 4.5rem;
  background-color: #fff;
  background-color: var(--input-bg);
  border: 0;
  border: var(--input-border);
  border-radius: 0;
  border-radius: var(--input-border-radius);
  padding: 0 1rem;
  padding: var(--input-padding);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  color: #858585;
  color: var(--input-color);
  font-size: 1.3rem;
  font-size: var(--input-font-size);
  font-weight: 700;
  text-align: left;
  outline: none;
  min-width: 4rem;
}

textarea {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  resize: none;
  text-align: left;
  padding: 0.5rem 1rem;
  line-height: calc(1.3rem + 0.2rem);
  line-height: calc(var(--input-font-size) + 0.2rem);
}

textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

[type='text']:focus,
[type='email']:focus,
[type='tel']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='search']:focus,
[type='button']:focus,
[type='color']:focus,
[type='time']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='file']:focus,
[type='image']:focus,
[type='month']:focus,
[type='reset']:focus,
[type='week']:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  outline: none;
}

option {
  background-color: #fff;
  background-color: var(--input-bg);
  color: #858585;
  color: var(--input-color);
  font-size: 1.3rem;
  font-size: var(--input-font-size);
  font-weight: 700;
  text-transform: uppercase;
}

option:hover {
}

/* Оформление checkbox */
.checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #fff;
}

.checkbox__label {
  height: 1.8rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox__name {
  margin-right: 1rem;
}

.checkbox__block {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  background-color: #fff;
  background-color: var(--input-bg);
  background: #ffffff;
  border: 0;
  border: var(--input-border);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0.3rem;
  position: relative;
  cursor: pointer;
  margin-right: 1rem;
}

.checkbox__block:after {
  font-family: 'gwi';
  content: '\e806';
  position: absolute;
  top: 0;
  left: 0.3rem;
  opacity: 0;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
  pointer-events: none;
  font-size: 1.1rem;
  color: #398fcd;
  color: var(--color-blue);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.checkbox__input {
  display: none;
}

.checkbox__input:checked + .checkbox__block:after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

/* field */
.field__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.field {
  position: relative;
  width: 100%;
}

.field_grp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: white;
  border: 0.1rem solid #cacaca;
  border-radius: 0.5rem;
  color: #858585;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
}

.field_mb {
  margin-bottom: 1.2rem;
}

.field__desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #585757;
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  width: 13rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-color: #e9ecef;
  border-right: 0.1rem solid #cacaca;
}

.field__ico {
  width: 3rem;
  height: auto;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0.5rem;
}

.field__img {
  min-height: 2rem;
}

.field__box {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-align: center;
}

.field__box_prl {
  padding: 0 1rem;
}

.field__box_br {
  border-right: 0.1rem solid #cacaca;
}

.field__box_bl {
  border-left: 0.1rem solid #cacaca;
}

.field__box_color_grey {
  background-color: #e9ecef;
}

.field__tool {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 3.5rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.field__tool-trigger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.inp-error {
  color: rgb(255, 84, 18);
  margin-bottom: 0.5rem;
}

.inp-error ~ input {
  outline: 0.1rem solid rgb(255, 84, 18);
}

.inp_login_pl {
  padding-left: 8rem;
}

.protection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}

.protection_mb {
  margin-bottom: 1.2rem;
}

.protection__captcha {
  width: 30.6rem;
  text-align: center;
}

.protection__captcha img,
.protection__captcha iframe {
  width: 30.6rem;
}

.agreement {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.agreement_mb {
  margin-bottom: 1.2rem;
}

.agreement__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #e9ecef;
  border: 0.1rem solid #cacaca;
  color: #565656;
  font-size: 1.1rem;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 0.5rem;
}

.agreement__content {
  padding: 0.4rem 0.5rem;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

.agreement__link {
  text-decoration: none;
  color: #565656;
}

.agreement__link:hover {
  color: #b6862d;
}

.agreement__check {
  width: 3rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.agreement__check_left {
  border-right: 0.1rem solid #cacaca;
}

.agreement__check_right {
  border-right: 0.1rem solid #cacaca;
}

.form__btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 0;
}

/* prefix */
.field__prefix {
  min-width: 3.5rem;
  text-align: center;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.prefix {
  position: relative;
  z-index: 5;
  cursor: pointer;
}

.prefix__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  width: 100%;
  position: relative;
  z-index: 0;
}

.prefix__list {
  background-color: #fff;
  color: rgb(133, 133, 133);
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0;
  position: absolute;
  left: 0;
  top: calc(100% - 5rem);
  width: 8rem;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.1rem solid #232323;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow: hidden;
  text-align: left;
}

.prefix__active ~ .prefix__list {
  top: calc(100% + 0.3rem);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.prefix__check {
  line-height: 3rem;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  padding-left: 0.5rem;
}

.prefix__check:hover {
  background-color: #d8d8d8;
}

.prefix__input:not([type='checkbox']):not([type='radio']) {
  background-color: #fff;
  height: 3rem;
  min-height: 3rem;
  border-top: 0.1rem solid #232323;
  padding: 0.5rem;
  text-transform: none;
}

/* pass */
.pass {
  background-image: url(../images/icons/eye.svg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 2.2rem;
  height: 2.2rem;
  cursor: pointer;
}

[type='password'] + .pass,
[type='password'] + .field__tool .pass {
  background-image: url(../images/icons/eye-closed.svg);
}

/* main */
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  font-family: 'Alegreya Sans SC', 'PT Sans', Tahoma, Arial, sans-serif;
  font-family: var(--font-main);
  font-size: 10px;
  font-weight: 400;
  color: #fff;
  color: var(--color-main);
  cursor: default;
}

body {
  position: relative;
  z-index: 0;
  overflow-x: hidden;
  margin: 0;
  background-color: #030000;
  background-color: var(--background-body);
  min-width: 470px;
  min-width: var(--min-width);
}

.page {
  position: relative;
  z-index: 0;
  overflow-x: hidden;
  background: #030000 url(../images/bg/bg-mobile.jpg) no-repeat center top;
  background: var(--background-body) url(../images/bg/bg-mobile.jpg) no-repeat
    center top;
  width: 100%;
  min-height: 100vh;
  min-width: 470px;
  min-width: var(--min-width);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: calc(6.1rem);
}

.modal-active .page {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  overflow: hidden !important;
}

.section {
  position: relative;
  z-index: 2;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.box {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.container {
  max-width: 109rem;
  min-width: 470px;
  min-width: var(--min-width);
  width: 100%;
  padding-right: calc(10px * 2);
  padding-right: calc(var(--margin) * 2);
  padding-left: calc(10px * 2);
  padding-left: calc(var(--margin) * 2);
  position: relative;
  z-index: 2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.rw {
  margin-left: -1rem;
  margin-right: -1rem;
}

.cl {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
}

.wrapper {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 2;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.spacer {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-height: 6.5rem;
}

.bar {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(calc(-50% - 0.3rem));
  -ms-transform: translateY(calc(-50% - 0.3rem));
  transform: translateY(calc(-50% - 0.3rem));
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* gap: 3rem; */
  right: 2.6rem;
}

.bar__item {
  margin-right: 3em;
}

/* Фиксируем позицию body, которую меняет панель гугла*/
.body_fix {
  top: 0 !important;
  position: static !important;
}

/* Прячем панель гугла */
.skiptranslate {
  display: none !important;
}

/* Убираем подсветку ссылок */
.goog-text-highlight {
  background-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
}

/* banners */
.banners {
  display: none;
  position: absolute;
  right: 5rem;
  top: 50%;
  -webkit-transform: translateY(calc(-50% + 0.3rem));
  -ms-transform: translateY(calc(-50% + 0.3rem));
  transform: translateY(calc(-50% + 0.3rem));
  z-index: 10;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 30rem;
}

.banner {
  text-decoration: none;
}

.banner__img {
  height: 3.8rem;
}

/* arrows */
.arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.arrows__arrow,
.arrow {
  width: 1.8rem;
  height: 1.8rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  color: #fff;
  outline: 0;
  border: 0;
  background: transparent;
  position: relative;
  z-index: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.2rem;
}

.arrows__arrow {
  opacity: 1;
}

.arrows__arrow:hover {
  opacity: 0.7;
}

.arrows__arrow.swiper-button-disabled,
.arrow.swiper-button-disabled {
  opacity: 0.2;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
}

.arrows__arrow:before,
.arrow:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 1px;
  z-index: -1;
}

.arrows__arrow_prev,
.arrow_prev {
  padding-right: 0.2rem;
}

.arrows__arrow_next,
.arrow_next {
  padding-left: 0.2rem;
}

.slider-prev {
  background: url(../images/slider-prev.png) no-repeat center center;
  background-size: contain;
  width: 2.3rem;
  height: 2.7rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.slider-next {
  background: url(../images/slider-next.png) no-repeat center center;
  background-size: contain;
  width: 2.3rem;
  height: 2.7rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

/* share */
.share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.share__item {
  text-decoration: none;
  display: inline-block;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  margin: 0.1rem;
}

.share__item:hover {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.share__img {
  display: block;
  width: auto;
  height: 1.4rem;
}

/* mid */
.mid {
  display: block;
  position: relative;
  z-index: 0;
  padding-top: 0.7rem;
}

.footer-hide .mid {
  margin-bottom: 15px;
}

.mid:after {
  display: block;
  background-image: url(../images/bg/mid.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 156rem;
  height: 67rem;
  position: absolute;
  /* left: calc(50%); */
  /* transform: translateX(calc(-50% + 0.6rem)); */
  left: calc(50% - 155rem / 2);
  top: -1.5rem;
  z-index: -1;
  /* @media (width >4000px) {
        width: calc(161.5rem + 3.2rem);
        height: calc(67rem + 2.2rem);
    } */
}

/* @media (width >=1100px) and (width <1300px) and (height < 1000px) and (orientation: landscape) {
        width: 158.4rem;
        left: calc(50% - 157rem / 2);
        top: -1rem;
    } */
.mid__box {
  margin-bottom: 1rem;
}

.mid__slider {
  margin-bottom: 1rem;
  min-width: 0;
}

.mid__content {
  min-width: 0;
  background: rgba(19, 18, 18, 0.81176);
  background: #131212cf;
}

/* ps */
.ps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ps__img {
  display: block;
}

/* preload */
.preload {
  background-color: #030000;
  background-color: var(--background-body);
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s cubic-bezier(0.64, 0.14, 0.34, 1.32);
  -o-transition: all 0.5s cubic-bezier(0.64, 0.14, 0.34, 1.32);
  transition: all 0.5s cubic-bezier(0.64, 0.14, 0.34, 1.32);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 1rem;
}

.preload__logo {
  max-width: 100%;
  width: 16.5rem;
  height: auto;
  display: block;
}

.preload__fade {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

@-webkit-keyframes preanimateout {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
  }
}

@keyframes preanimateout {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
  }
}

/* hint */
.hint {
  position: relative;
  z-index: 0;
  -webkit-perspective: 700px;
  perspective: 700px;
}

.hint__btn {
  width: 3.3rem;
  height: 3.3rem;
  border-radius: 50%;
  cursor: default;
  position: relative;
  z-index: 5;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.hint__btn_bg {
  background: url(../images/hint/hint__btn.png) no-repeat center center;
  background-size: contain;
}

.hint__btn_cursor_pointer {
  cursor: pointer;
}

.hint__btn_cursor_pointer:hover {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.hint__btn_js_toggle {
  cursor: pointer;
}

.hint__btn_js_toggle:hover {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.hint__arrow {
  content: '';
  display: block;
  width: 1.5rem;
  height: 1.9rem;
  position: absolute;
  top: 0.9rem;
  left: 1rem;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  z-index: 20;
}

.hint__arrow:after {
  display: block;
  content: '';
  background-image: url(../images/arrow_down_normal.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 3.3rem;
  height: 3.9rem;
  position: absolute;
  top: -0.9rem;
  left: -1rem;
}

.hint__arrow_size_small:after {
  width: 2.64rem;
  height: 3.12rem;
  position: absolute;
  top: -0.5rem;
  left: -0.6rem;
}

.hint__list {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) rotateX(-90deg);
  transform: translateX(-50%) rotateX(-90deg);
  width: 16.4rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgba(3, 3, 4, 0.8);
  border-top-left-radius: 2.6rem;
  border-top-right-radius: 2.6rem;
  padding-top: 0.3rem;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-transform-origin: center top;
  -ms-transform-origin: center top;
  transform-origin: center top;
}

.hint__list:before {
  display: block;
  content: '';
  background: url(../images/hint/hint__decor_size_normal.png) no-repeat;
  background-size: contain;
  width: 16.7rem;
  height: 3.2rem;
  position: absolute;
  top: -0.2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  pointer-events: none;
}

.hint__item {
  text-decoration: none;
  display: block;
  color: #b7b07d;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.5rem;
  text-transform: uppercase;
  padding: 0.9rem 0.4rem 0.7rem 0.4rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  z-index: 3;
}

.hint__item_rl {
  border-top-left-radius: 2.6rem;
}

.hint__item_rr {
  border-top-right-radius: 2.6rem;
}

.hint__item:hover {
  background-color: rgba(61, 57, 53, 0.3);
  color: #fff;
}

.hint__item:after {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 0;
  border-bottom: 1px solid rgba(60, 57, 46, 0.5);
  border-top: 1px solid rgba(32, 31, 26, 0.5);
}

.hint__content {
  width: calc(100% - 1.8rem);
  margin: 0 0.9rem;
}

/* hint_size_large */
.hint_size_large .hint__list {
  width: 23rem;
}

.hint_size_large .hint__list:before {
  display: block;
  content: '';
  background: url(../images/hint/hint__decor_size_large.png) no-repeat;
  background-size: contain;
  width: 23.4rem;
}

/* hint_size_large */
.hint_size_small .hint__list {
  width: 11.8rem;
}

.hint_size_small .hint__list:before {
  display: block;
  content: '';
  background: url(../images/hint/hint__decor_size_smal.png) no-repeat;
  background-size: contain;
  width: 12rem;
}

/* hint__btn_active */
.hint__btn_active .hint__arrow {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.hint__btn_active + .hint__list {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* hint-hover */
.hint-hover:hover .hint__btn {
  pointer-events: none;
}

.hint-hover:hover .hint__arrow {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.hint-hover:hover .hint__list {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* fbtn */
.fbtn {
  width: 3.2rem;
  height: 3.2rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.fbtn:hover {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}

.fbtn:before {
  content: '';
  display: block;
  width: 4.4rem;
  height: 4.7rem;
  position: absolute;
  top: -0.4rem;
  left: -0.6rem;
  z-index: -1;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  background: url(../images/fbtn/fbtn.png) no-repeat;
  background-size: contain;
  pointer-events: none;
}

.fbtn__pic {
  width: 4rem;
  height: 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.fbtn__img {
  width: 3.6rem;
  height: 3.6rem;
}

/* btn */
.btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
  width: 14rem;
  height: 3.2rem;
  text-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.22);
  color: white;
  font-size: 2rem;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  position: relative;
  z-index: 0;
  border: 0;
  outline: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-shadow: 0 0 1.5rem #466018;
  box-shadow: 0 0 1.5rem #466018;
  font-family: 'Alegreya Sans SC', 'PT Sans', Tahoma, Arial, sans-serif;
  font-family: var(--font-main);
}

.btn:hover {
  -webkit-box-shadow: 0 0 2rem #466018;
  box-shadow: 0 0 2rem #466018;
}

.btn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
  background: transparent url(../images/btn/btn.jpg) no-repeat center center;
  background-size: contain;
  pointer-events: none;
}

.btn:hover:after {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}

.btn__content {
  position: relative;
  z-index: 5;
  display: inline-block;
}

.btn__ico {
  display: inline-block;
  position: relative;
  z-index: 5;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.btn__ico_m_l {
  margin-left: 0.5rem;
}

.btn__ico_m_r {
  margin-right: 0.5rem;
}

/* btn_size_small */
.btn_size_small {
  width: 10.7rem;
  height: 2.5rem;
  color: white;
  font-family: 'Alegreya Sans SC', 'PT Sans', Tahoma, Arial, sans-serif;
  font-family: var(--font-main);
  font-size: 1rem;
  font-weight: 700;
  font-style: normal;
}

.btn_size_small:after {
  background: transparent url(../images/btn/btn_size_small.jpg) no-repeat center
    center;
  background-size: contain;
}

/* btn_size_large */
.btn_size_large {
  width: 100%;
  height: 5.2rem;
  color: white;
  font-size: 1.6rem;
  font-weight: 900;
  font-style: italic;
  -webkit-box-shadow: none;
  box-shadow: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.btn_size_large:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn_size_large:after {
  background: transparent url(../images/btn/btn_size_large.jpg) no-repeat center
    center;
  background-size: 100% 100%;
}

/* button */
.button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
  width: 25.2rem;
  height: 5rem;
  text-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.18);
  color: white;
  font-size: 1.7rem;
  font-weight: 900;
  text-transform: uppercase;
  font-family: 'Alegreya Sans SC', 'PT Sans', Tahoma, Arial, sans-serif;
  font-family: var(--font-main);
  position: relative;
  z-index: 0;
  border: 0;
  outline: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.button:hover {
}

.button:after {
  content: '';
  display: block;
  position: absolute;
  top: -0.4rem;
  left: -0.5rem;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
  background: transparent url(../images/button/button.png) no-repeat center
    center;
  background-size: contain;
  pointer-events: none;
  width: 26.2rem;
  height: 5.8rem;
  z-index: -1;
}

.button:hover:after {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}

.button__content {
  position: relative;
  z-index: 5;
  display: inline-block;
}

/* lang */
.lang {
  width: 3.2rem;
  height: 3.2rem;
  position: relative;
}

.lang:before {
  content: '';
  display: block;
  width: 4.4rem;
  height: 4.7rem;
  position: absolute;
  top: -0.4rem;
  left: -0.6rem;
  z-index: 10;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  background: url(../images/lang/lang__framing.png) no-repeat;
  background-size: contain;
  pointer-events: none;
}

.lang__hint:before {
  display: block;
  content: '';
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  left: 0.7rem;
  top: -0.2rem;
  z-index: 25;
  border-radius: 50%;
}

.lang:after {
  content: '';
  display: block;
  background: url(../images/arrow_down_small.png) no-repeat center bottom;
  background-size: contain;
  width: 3.1rem;
  height: 2.8rem;
  position: absolute;
  left: 0rem;
  top: 2.2rem;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  cursor: pointer;
  z-index: 20;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  pointer-events: none;
}

.lang:hover:after {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.lang .hint__list {
  -webkit-transform: translateX(-53%) rotateX(-90deg);
  transform: translateX(-53%) rotateX(-90deg);
}

.lang:hover .hint__list {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  -webkit-transform: translateX(-53%);
  -ms-transform: translateX(-53%);
  transform: translateX(-53%);
}

.lang__list {
  padding-top: 0.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.lang__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.lang__item:after {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 0;
  border-bottom: 1px solid rgba(60, 57, 46, 0.5);
  border-top: 1px solid rgba(32, 31, 26, 0.5);
}

.lang__img {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.lang__img-current {
  min-width: 100%;
}

.lang__link {
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.lang__link_sub {
  margin: 0.5rem 0;
}

.lang__link_sub:hover {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.lang__hint .hint__list {
  width: 32rem;
  left: -8rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.lang__hint .hint__list:before {
  display: block;
  content: '';
  background: url(../images/lang/lang__hint.png) no-repeat;
  background-size: contain;
  width: 32.2rem;
  height: 3.2rem;
}

/* logo */
.logo {
  text-decoration: none;
  display: inline-block;
  position: relative;
  z-index: 0;
  line-height: 0;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  max-height: 100%;
}

.logo__img {
  max-height: 100%;
  display: block;
  height: 5rem;
}

.logo:hover {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}

.logo_pos {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10;
  left: 1.5rem;
}

.logo_nav {
  -webkit-transform: translateY(calc(-50% - 0.2rem));
  -ms-transform: translateY(calc(-50% - 0.2rem));
  transform: translateY(calc(-50% - 0.2rem));
}

.logo_footer {
  -webkit-transform: translateY(calc(-50%));
  -ms-transform: translateY(calc(-50%));
  transform: translateY(calc(-50%));
  display: none;
}

/* nav */
.nav {
  position: relative;
}

.nav__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav__link {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: calc(0.3rem) calc(1.2rem);
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.nav__ico {
  width: calc(5rem);
  height: calc(5rem);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: calc(0.9rem);
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
}

.nav__img {
  max-width: calc(6rem);
  min-width: 100%;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-filter: brightness(105%);
  filter: brightness(105%);
}

.nav__name {
  text-shadow: 0 calc(0.3rem) calc(0.3rem) rgba(0, 0, 0, 0.36);
  color: white;
  font-family: 'AlbertusExtraBold', 'Times New Roman', Times, serif;
  font-family: var(--font-second);
  font-size: calc(1.4rem);
  text-transform: uppercase;
  white-space: nowrap;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  font-weight: 800;
}

.nav__desc {
  text-shadow: 0 calc(0.3rem) calc(0.3rem) rgba(0, 0, 0, 0.36);
  color: #fcd601;
  font-family: 'AlbertusExtraBold', 'Times New Roman', Times, serif;
  font-family: var(--font-second);
  font-size: calc(1rem);
  font-weight: 800;
  text-transform: uppercase;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.nav__link:hover .nav__name {
  color: #ffc923;
}

.nav__link:hover .nav__desc {
  color: #9b7f2d;
}

/* Кнопка меню */
.gw-burger {
  position: relative;
  z-index: 5;
  background-color: transparent;
  height: calc(3.6rem);
  width: calc(4rem);
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
}

.gw-burger:after {
  display: block;
  content: '';
  background: url(../images/navigation/gw-burger-bg.png) no-repeat;
  background-size: contain;
  width: calc(6rem);
  height: calc(5.8rem);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.gw-burger__open {
  background: url(../images/navigation/gw-burger-open.png) no-repeat;
  background-size: contain;
  width: calc(4.5rem);
  height: calc(4.3rem);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.gw-burger__close {
  background: url(../images/navigation/gw-burger-close.png) no-repeat;
  background-size: contain;
  width: calc(5.5rem);
  height: calc(5.5rem);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}

.mob-nav-active .gw-burger__open {
  opacity: 0;
}

.mob-nav-active .gw-burger__close {
  opacity: 1;
}

/* navigation */
[data-section='navigation'] {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 15;
}

.modal-active [data-section='navigation'] {
  z-index: 6;
}

[data-section='navigation']:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #100f0d url(../images/navigation/bg.jpg) no-repeat center top;
  background-size: cover;
  z-index: -1;
}

.navigation {
  height: calc(6.1rem);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: calc(0.3rem);
}

.navigation:after {
  content: '';
  display: block;
  width: 100%;
  height: 14px;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  background: url(../images/navigation/decor.png) no-repeat center center;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 20;
}

.navigation__nav {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* start */
.start {
  position: relative;
  z-index: 5;
}

.start__btn {
  font-family: 'AlbertusExtraBold', 'Times New Roman', Times, serif;
  font-family: var(--font-second);
  width: 27rem;
  height: 12rem;
  position: relative;
  z-index: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  text-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.18);
  color: white;
  font-size: 1.8rem;
  text-transform: uppercase;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 0;
  letter-spacing: 0.2rem;
  /* outline: 1px solid #fff; */
}

.start__btn:hover {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}

.start__btn:after {
  display: block;
  content: '';
  background-image: url(../images/start/start.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 35.3rem;
  height: 14.1rem;
  pointer-events: none;
  position: absolute;
  left: -4rem;
  top: -0.5rem;
  z-index: -1;
  /* outline: 1px solid #fff; */
}

.start__hint {
  position: absolute;
  top: calc(100% - 2.8rem);
  z-index: 10;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* @media (width < 550px) {
    .start__hint .hint__list {
        display: none;
    }
} */
/* life */
.life__title {
  color: #9d9894;
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
}

.life__days {
  color: white;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 900;
}

.life__days_name {
}

.life__time {
  color: #efff6b;
  font-size: 1.5rem;
  font-weight: 900;
  text-transform: uppercase;
}

/* server */
.server {
  width: 13.6rem;
  height: 15.9rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  position: relative;
  border-radius: 40%;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  margin: 16rem auto;
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  z-index: 3;
}

.server:before {
  content: '';
  display: block;
  background: url(../images/servers/server__bg.png) no-repeat;
  background-size: contain;
  width: 13.6rem;
  height: 15.9rem;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  z-index: -1;
  pointer-events: none;
}

.server:nth-child(2) {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  z-index: 2;
}

.server__progress {
  width: 10.8rem;
  height: 10.8rem;
  position: absolute;
  left: 1.5rem;
  top: 2.5rem;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.server_status_off .server__progress {
  background-image: url(../images/servers/server__load_progress_off.png);
}

.server_status_on .server__progress {
  -webkit-filter: drop-shadow(0 0 5px rgba(190, 10, 10, 0.9));
  filter: drop-shadow(0 0 5px rgba(190, 10, 10, 0.9));
}

.server__progress canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 10.8rem !important;
  height: 10.8rem !important;
}

.server__link {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}

.server__hint {
  position: absolute;
  bottom: 0.2rem;
  left: 0;
  right: 0;
  z-index: 20;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.server__status {
  height: 2.5rem;
  position: absolute;
  left: 0.2rem;
  right: 0;
  top: 0;
  z-index: 4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 0.1rem;
}

.server__state_online {
  text-shadow: 0 0 1.8rem rgba(242, 21, 21, 0.84),
    0 0 1.8rem rgba(242, 21, 21, 0.84), 0 0 1.8rem rgba(242, 21, 21, 0.84);
  color: #ff443b;
  font-family: 'AlbertusExtraBold', 'Times New Roman', Times, serif;
  font-family: var(--font-second);
  font-size: 0.7rem;
  text-transform: uppercase;
}

.server_status_on .server__state_online {
  text-shadow: 0 0 1.8rem rgba(92, 157, 37, 0.84),
    0 0 1.8rem rgba(92, 157, 37, 0.84), 0 0 1.8rem rgba(92, 157, 37, 0.84);
  color: #bbff3c;
}

.server__content {
  font-family: 'AlbertusExtraBold', 'Times New Roman', Times, serif;
  font-family: var(--font-second);
  position: relative;
  z-index: 5;
  width: 7rem;
  height: 7rem;
  text-align: center;
}

.server__front {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.server__title {
  font-size: 1.1em;
  font-weight: 900;
  text-transform: uppercase;
  color: #a7a18a;
  line-height: 1em;
}

.server__name {
  color: #ffd800;
  font-size: 1.1em;
}

.server__back {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.server__online {
  color: white;
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: 900;
  text-transform: uppercase;
}

/* server:hover  */
.server:hover .server__back {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.server:hover .server__front {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}

/* servers */
[data-section='servers'] {
  position: relative;
  z-index: 5;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-height: none;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.servers {
  max-height: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 4rem;
  position: relative;
  z-index: 0;
}

.servers__btn-box {
  width: 27rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
  margin: 8rem auto;
  -webkit-transform: scale(1.6);
  -ms-transform: scale(1.6);
  transform: scale(1.6);
}

/* snews */
.snews {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.snews__slider {
  position: relative;
  z-index: 0;
}

.snews__slide {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4rem 1.5rem 2.7rem 1.5rem;
  min-height: 27.7rem;
  background-image: url(../images/snews/img-def.jpg);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.snews__inner {
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.snews__title {
  font-size: 3rem;
  line-height: 1;
  font-weight: 900;
  font-style: italic;
  color: white;
  text-transform: uppercase;
  margin: 0;
  text-shadow: 0 0.3rem 0.4rem rgba(0, 0, 0, 0.8);
  padding-bottom: 20px;
}

.snews__sub-title {
  font-size: 1.8rem;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-bottom: 0.5rem;
}

.snews__date {
  color: white;
  font-family: 'Alegreya Sans SC', 'PT Sans', Tahoma, Arial, sans-serif;
  font-family: var(--font-main);
  font-size: 1.5rem;
  font-weight: 700;
  font-style: italic;
  letter-spacing: 0.3rem;
}

.snews__btn {
  margin-top: 0.6rem;
  font-size: 1.3rem;
  font-weight: 900;
  font-style: italic;
  padding-top: 0.4rem;
}

.snews__share {
  margin-top: 1.1rem;
}

.snews__counter {
  width: 3rem;
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.6rem;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  font-family: 'AlbertusExtraBold', 'Times New Roman', Times, serif;
  font-family: var(--font-second);
  letter-spacing: 0.2rem;
}

.snews__arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  right: 0.6rem;
  top: 1.3rem;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  height: 3.5rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

[data-slider-pagination='snews'] {
  position: absolute;
  left: 0;
  right: 0;
  top: 2.8rem;
  z-index: 10;
  bottom: auto !important;
}

.pag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pag__bullet {
  width: 2.2rem;
  height: 0.3rem;
  background-color: #6f6f6f;
  margin: 0 0.2rem;
  cursor: pointer;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.pag__bullet:hover {
  background-color: #ffa555;
}

.pag__bullet.swiper-pagination-bullet-active {
  background-color: #ffa555;
  -webkit-box-shadow: 0 0.2rem 0 rgba(87, 50, 13, 0.34), 0 0 5px red,
    0 0 10px red;
  box-shadow: 0 0.2rem 0 rgba(87, 50, 13, 0.34), 0 0 5px red, 0 0 10px red;
}

/* item */
.item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 5.84rem;
  text-decoration: none;
  color: white;
  font-size: 1.3rem;
  font-weight: 400;
  position: relative;
  z-index: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.2rem 0.4rem 0.2rem 1rem;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
}

.item:after {
  display: block;
  content: '';
  background-color: 0.2;
  -webkit-box-shadow: inset 0 0 3.2rem rgba(127, 93, 49, 0.2);
  box-shadow: inset 0 0 3.2rem rgba(127, 93, 49, 0.2);
  position: absolute;
  top: 0.1rem;
  right: 0;
  bottom: 0.1rem;
  left: 0;
  z-index: -1;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
  opacity: 0.55;
}

.item:hover:after {
  opacity: 1;
}

.item__date {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 8.2rem;
  height: 2.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 1rem;
  position: relative;
  z-index: 0;
  text-shadow: 0 0.3rem 0.2rem rgba(0, 0, 0, 0.34);
  color: white;
  font-size: 1.1rem;
  font-family: 'AlbertusExtraBold', 'Times New Roman', Times, serif;
  font-family: var(--font-second);
}

.item__date:after {
  display: block;
  content: '';
  background: url(../images/items/date.png) no-repeat;
  background-size: contain;
  width: 16.9rem;
  height: 9rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.item__content {
  min-width: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 1rem;
}

.item__title {
  color: #fef4de;
  font-family: 'AlbertusExtraBold', 'Times New Roman', Times, serif;
  font-family: var(--font-second);
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

.item__desc {
  color: #c5b186;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

.item__btns {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 10.7rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
  opacity: 0.55;
}

.item:hover .item__btns {
  opacity: 1;
}

/* items */
.items {
}

.items__list_short {
  max-height: 18rem;
  overflow: hidden;
}

/* tabs */
.tabs {
}

.tabs__more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tabs__btns {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: 5rem;
  overflow: hidden;
  background-color: transparent;
  position: relative;
  z-index: 2;
}

.tabs__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 0;
  cursor: pointer;
  text-shadow: 0 0.5rem 0.2rem rgba(0, 0, 0, 0.39);
  color: #ffe5ac;
  font-family: 'AlbertusExtraBold', 'Times New Roman', Times, serif;
  font-family: var(--font-second);
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  opacity: 0.5;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  padding-top: 0.3rem;
}

.tabs__btn:after {
  display: block;
  content: '';
  background-image: url(../images/tabs/btn-hover.png);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  width: calc(100% + 0.2rem);
  height: 5.7rem;
  position: absolute;
  top: 0;
  left: -0.1rem;
  z-index: -1;
  opacity: 0;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.tabs__btn:before {
  display: block;
  content: '';
  background-image: url(../images/tabs/btn.png);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  width: calc(100% + 0.2rem);
  height: 5.7rem;
  position: absolute;
  top: 0;
  left: -0.1rem;
  z-index: -2;
}

.tabs__btn_active:after {
  opacity: 1;
}

.tabs__btn:hover {
  opacity: 0.8;
}

.tabs__btn_active,
.tabs__btn_active:hover {
  opacity: 1;
}

.tabs__content {
  overflow: hidden;
}

.tabs__tab {
  display: none;
  -webkit-animation: tab 1s ease forwards;
  animation: tab 1s ease forwards;
}

@-webkit-keyframes tab {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes tab {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* stream */
.stream {
  /* height: 12rem; */
  height: calc(55vw);
  background-image: url(../images/streams/bg.jpg);
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  cursor: default;
  border: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  margin-top: 1rem;
}

.stream:nth-child(5) {
  display: none;
}

.stream_fullscreen {
  display: none;
}

.stream_fullscreen_visible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.stream iframe {
  width: 100%;
  height: 100%;
}

.stream__link {
  text-decoration: none;
  color: rgb(196, 132, 49);
}

.stream__link:hover {
  color: rgb(230, 154, 56);
}

.stream__add {
  width: 7rem;
  height: 7rem;
  position: relative;
  z-index: 0;
  cursor: pointer;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  border-radius: 50%;
}

.stream__add:hover {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.stream__add:after {
  display: block;
  content: '';
  background: url(../images/streams/add__plus.png) no-repeat;
  background-size: contain;
  width: 8.7rem;
  height: 7.9rem;
  position: absolute;
  left: -1rem;
  top: -0.7rem;
  z-index: -1;
  pointer-events: none;
}

/* streams */
/* scl */
.scl {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.8rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -0.6rem;
  /* display: grid; */
  /*  grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr)); */
  position: relative;
  z-index: 0;
}

.scl:after {
  display: block;
  background: url(../images/scl/reflection.png) no-repeat;
  background-size: contain;
  width: 116rem;
  height: 14.5rem;
  position: absolute;
  top: calc(100% - 4.2rem);
  left: calc(50% - 58rem);
  z-index: -1;
}

.scl__link {
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% / 3 - 0.5rem * 2);
  margin: 0.47rem;
  position: relative;
  z-index: 0;
  color: #fff;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.scl__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* .scl__link {
    transition: .3s all;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    background-image: url(../images/scl/bg-skype.jpg);
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    color: #fff;
    text-decoration: none;
    box-sizing: border-box;
    padding-bottom: 1rem;
    width: calc(100% / 3 - 0.5rem * 2);
    height: calc((100vw - var(--margin) * 3) / 3 - 0.5rem * 2);
    min-height: calc((var(--min-width) - var(--margin) * 3) / 3 - 0.5rem * 2);
    margin: 0.5rem;

    @media (width >=600px) {
        width: calc(100% / 9 - 0.5rem * 2);
        height: calc((100vw - var(--margin) * 2) / 9 - 0.5rem * 2);
        min-height: calc((100vw - var(--margin) * 2) / 9 - 0.5rem * 2);
    }

    @media (width >=800px) {
        width: calc(100% / 9 - 0.5rem * 2);
        height: calc((100vw - var(--margin) * 2) / 9 - 0.5rem * 2);
        min-height: calc((100vw - var(--margin) * 2) / 9 - 0.5rem * 2);
        margin-top: 0;
        margin-bottom: 0;
    }

    @media (width >=1000px) {
        width: calc(100% / 9 - 0.52rem * 2);
        height: 10.8rem;
        min-height: 10.8rem;
    }

    @media (width >=1000px) and (orientation: portrait) {
        width: calc(100% / 9 - 0.52rem * 2);
        height: 7.4rem;
        min-height: 90px;
        aspect-ratio: 1 / 1;
    }

} */
.scl__link:hover {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.scl__img {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
}

.scl__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 8rem;
  text-align: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.scl__gwi {
  font-size: 6rem;
}

.scl__line {
  max-width: 90%;
  width: 6rem;
  height: 0.1rem;
  margin: 0 auto;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    color-stop(50%, rgba(255, 255, 255, 0.5)),
    to(rgba(255, 255, 255, 0))
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.scl__name {
  margin-top: 0.5rem;
  text-shadow: 0 0.4rem 0 rgba(0, 0, 0, 0.38), 0 0 1rem rgba(202, 77, 46, 0.56);
  color: white;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.12rem;
}

.scl__vkontakte:before {
  width: 1.3em;
}

.scl__discord:before {
  width: 2em;
  font-size: 0.8em;
}

.scl__email {
  -webkit-transform: rotate(-15deg) translateX(-0.2rem);
  -ms-transform: rotate(-15deg) translateX(-0.2rem);
  transform: rotate(-15deg) translateX(-0.2rem);
}

.scl__telegram {
  font-size: 4rem;
  -webkit-transform: translateX(-0.2rem);
  -ms-transform: translateX(-0.2rem);
  transform: translateX(-0.2rem);
}

/* copyright */
.copyright {
}

.copyright__bnrs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.copyright__bnr {
  margin-right: 0.7rem;
}

.copyright__img {
  height: 5.2rem;
  width: auto;
}

.copyright__txt {
  color: #545454;
  font-family: 'AlbertusExtraBold', 'Times New Roman', Times, serif;
  font-family: var(--font-second);
  font-size: 0.8rem;
  margin-top: 0.6rem;
  line-height: 1rem;
}

.copyright__site {
  color: white;
  font-size: 1rem;
}

.copyright__policy {
  color: #b6b37c;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.copyright__policy:hover {
  color: #dbd49a;
}

/* footer */
[data-section='footer'] {
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 4;
  margin-top: auto;
}

[data-container='footer'] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footer {
  display: block;
}

.footer__title {
  color: #fcd601;
  font-family: 'AlbertusExtraBold', 'Times New Roman', Times, serif;
  font-family: var(--font-second);
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  z-index: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 2.2rem;
  margin-bottom: 1rem;
}

.footer__title:before {
  display: block;
  content: '';
  background: url(../images/footer/ft.png) no-repeat;
  background-size: contain;
  position: absolute;
  left: -0.8rem;
  top: -0.5rem;
  width: 2.3rem;
  height: 2.4rem;
}

.footer__nav {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 76rem;
  flex-basis: 76rem;
}

.footer__f-nav {
  margin: 1.2rem;
  -ms-flex-preferred-size: 20rem;
  flex-basis: 20rem;
  /* flex-shrink: 0; */
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.footer__f-nav:nth-child(1) {
  padding-right: 0;
}

.f-nav__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.f-nav__item {
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #b6b37c;
  font-size: 1.4rem;
  font-weight: 600;
  /* text-transform: uppercase; */
  text-decoration: none;
  line-height: 1.4rem;
  position: relative;
  z-index: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 2.2rem;
  margin-bottom: 0.1rem;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.f-nav__item:hover {
  color: #dbd49a;
}

.f-nav__item:before {
  display: block;
  content: '';
  background: url(../images/footer/fn.png) no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0.1rem;
  width: 0.6rem;
  height: 1.2rem;
  opacity: 0.5;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.f-nav__item:hover:before {
  opacity: 1;
}

.footer__info {
  margin: 1.2rem 0 3rem 1.5rem;
  width: 22rem;
}

.footer-switcher {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
}

.footer-switcher__arrow:after {
  left: -0.6rem;
  top: -0.7rem;
  width: 2.6rem;
  height: 3rem;
}

.footer-hide .footer-switcher__arrow {
  -webkit-transform: rotateX(180deg) translateY(2px);
  transform: rotateX(180deg) translateY(2px);
}

/* under */
.under-logo {
  max-width: 130px;
}

[data-section='under'] {
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 4;
}

[data-section='under']:after {
  display: block;
  content: '';
  height: 0.1rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0)),
    color-stop(15%, #666),
    color-stop(85%, #666),
    to(rgba(0, 0, 0, 0))
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    #666 15%,
    #666 85%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    #666 15%,
    #666 85%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.3;
}

.under {
  min-height: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.under__ps {
  width: 100%;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

/* modal */
.modal-active {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 99;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-filter: blur(0);
  filter: blur(0);
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0 2rem;
  overflow-x: hidden;
}

.modal_none {
  display: none;
}

.modal_hide {
  opacity: 0;
}

.modal__close {
  background: url(../images/modal/close.png) no-repeat;
  background-size: contain;
  width: 1rem;
  height: 1rem;
  -webkit-transform-origin: 0.5rem 0.4rem;
  -ms-transform-origin: 0.5rem 0.4rem;
  transform-origin: 0.5rem 0.4rem;
  position: absolute;
  right: 0.4rem;
  top: 1.1rem;
  display: inline-block;
  z-index: 5;
  cursor: pointer;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-filter: drop-shadow(0 0 0.8rem rgba(0, 0, 0, 0.98))
    drop-shadow(0 0 1rem rgba(99, 99, 99, 0.27));
  filter: drop-shadow(0 0 0.8rem rgba(0, 0, 0, 0.98))
    drop-shadow(0 0 1rem rgba(99, 99, 99, 0.27));
}

.modal__inner {
  -ms-flex-preferred-size: 42.4rem;
  flex-basis: 42.4rem;
  height: auto;
  margin: auto;
  background-color: transparent;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  opacity: 1;
  position: relative;
  z-index: 10;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2rem 0;
  /* @media (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
        margin-top: 5%;
    } */
}

.modal_hide .modal__inner {
  -webkit-transform: scale(1.2) translateY(-100px);
  -ms-transform: scale(1.2) translateY(-100px);
  transform: scale(1.2) translateY(-100px);
  opacity: 0;
}

.modal__heading-decoration {
  background: url(../images/modal/modal__top.png) no-repeat;
  background-size: contain;
  width: 48.5rem;
  height: 9.5rem;
  position: absolute;
  right: -4.5rem;
  top: -1.3rem;
  pointer-events: none;
}

.modal__footer-decoration {
  background: url(../images/modal/modal__bottom.png) no-repeat;
  background-size: contain;
  width: 44.2rem;
  height: 5.6rem;
  position: absolute;
  right: -0.9rem;
  bottom: 0.4rem;
  pointer-events: none;
}

.modal__head {
  min-height: 8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 3rem;
}

.modal__title {
  color: #32325c;
  font-size: 1.8rem;
  text-align: center;
}

.modal__title_warning {
  color: #fc7c56;
  font-size: 1.8rem;
}

.modal__desc {
  color: #5b6787;
  font-size: 1.3rem;
  text-align: center;
}

.modal__desc_m_t {
  margin-top: 0.6rem;
}

.modal__content {
  background-color: #201e1d;
  background: -o-linear-gradient(
      10deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 1%,
      rgba(0, 0, 0, 0.04) 70%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    #201e1d url(../images/modal/bg-repeat.jpg) repeat;
  background: linear-gradient(
      80deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 1%,
      rgba(0, 0, 0, 0.04) 70%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    #201e1d url(../images/modal/bg-repeat.jpg) repeat;
  width: 100%;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2rem 3rem;
  font-size: 1.3rem;
  min-height: 10rem;
  transition: all 0.3s ease;
}

/* bhelp */
.bhelp_mt_files {
  margin-top: 1.5rem;
}

.bhelp__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.3rem 0;
}

.bhelp__ico {
  width: 3.3rem;
  height: 3.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0.6rem;
}

.bhelp__link {
  color: white;
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  padding: 0.3rem 0;
}

.bhelp__link:hover {
  color: rgb(253, 255, 144);
}

/* data-gw-tab="languages" */
[data-gw-tab='languages'] .select2 {
  width: 100% !important;
  height: 4.5rem !important;
}

[data-gw-tab='languages'] .slanguage {
  width: 100% !important;
  height: 4.5rem !important;
  line-height: 4.5rem !important;
  background-color: white !important;
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0 1rem;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  color: #858585 !important;
  font-size: 1.3rem !important;
  font-weight: 700 !important;
  text-align: left !important;
  outline: none !important;
  min-width: 4rem !important;
}

[data-gw-tab='languages'] .select2-selection__rendered {
  line-height: 4.5rem !important;
  padding-left: 0.2rem !important;
  color: rgb(133, 133, 133) !important;
}

[data-gw-tab='languages'] .select2-selection__arrow {
  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.olanguage .select2-results > .select2-results__options {
  max-height: 20rem;
}

.olanguage__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #858585 !important;
}

.olanguage__txt {
  padding-left: 1rem;
  font-size: 1.3rem !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}

.olanguage .select2-results__option[aria-selected='true'] .olanguage__txt {
  color: #000 !important;
}

.olanguage .select2-results__option--highlighted[aria-selected] {
  background-color: rgb(208, 226, 255) !important;
  color: rgb(255, 255, 255) !important;
}

.img-flag {
  width: 3.6rem;
  height: 3.6rem;
}

/* def */
.hidden {
  display: none;
}

.tt-uppercase {
  text-transform: uppercase;
}

.text-align-center {
  text-align: center;
}

[class^='gwi_']:before,
[class*=' gwi_']:before {
  margin: 0;
}

.pr {
  padding-right: 10px;
}

.pl {
  padding-left: 10px;
}

.pt {
  padding-top: 10px;
}

.pb {
  padding-bottom: 10px;
}

.mr {
  margin-right: 10px;
}

.ml {
  margin-left: 10px;
}

.mt {
  margin-top: 10px;
}

.mb {
  margin-bottom: 10px;
}

.m {
  margin: 10px;
}

.mra {
  margin-right: auto;
}

.mla {
  margin-left: auto;
}

.mta {
  margin-top: auto;
}

.ma {
  margin: auto;
}

.mba {
  margin-bottom: auto;
}

.mr0 {
  margin-right: 10px;
}

.ml0 {
  margin-left: 10px;
}

.mt0 {
  margin-top: 10px;
}

.mb0 {
  margin-bottom: 10px;
}

.m0 {
  margin: 0;
}

/* colors */
.color-light {
  color: #e8d1a6;
  color: var(--color-light);
}

.color-red {
  color: rgb(255, 39, 39);
  color: var(--color-red);
}

.color-yellow {
  color: #fcfc41;
  color: var(--color-yellow);
}

.color-orange {
  color: #fcd601;
  color: var(--color-orange);
}

.color-pink {
  color: rgb(255, 117, 140);
  color: var(--color-pink);
}

.color-light-blue {
  color: rgb(87, 160, 206);
  color: var(--color-light-blue);
}

.color-blue {
  color: #398fcd;
  color: var(--color-blue);
}

.color-green {
  color: #97c647;
  color: var(--color-green);
}

.color-grey {
  color: #c5c1c1;
  color: var(--color-grey);
}

.color-white {
  color: white;
  color: var(--color-white);
}

.color-aqua {
  color: #56dcee;
  color: var(--color-aqua);
}

/* other */
.sbttl {
  font-size: 18px;
  margin-top: 20px;
  display: inline-block;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fs095 {
  font-size: 0.95em;
}

.fs11 {
  font-size: 1.1em;
}

.fs12 {
  font-size: 1.2em;
}

.fs13 {
  font-size: 1.3em;
}

.fs14 {
  font-size: 1.4em;
}

.fs15 {
  font-size: 1.5em;
}

.fs2 {
  font-size: 2em;
}

.clear {
  clear: both;
}

.adaptive-portrait .page {
  background: #030000 url(../images/bg/bg-desktop.jpg) no-repeat center top;
  background: var(--background-body) url(../images/bg/bg-desktop.jpg) no-repeat
    center top;
  background-size: cover;
}

.adaptive-portrait [data-section='servers'] {
  max-height: 30rem;
}

.adaptive-portrait .bar {
  -ms-grid-columns: auto auto auto auto;
  grid-template-columns: auto auto auto auto;
}

.adaptive-portrait .gw-burger {
  display: block;
}

/* navigation */
.adaptive-portrait [data-container='navigation'] {
  max-width: none;
  width: 100%;
  padding: 0;
}

.adaptive-portrait .navigation {
  display: block;
}

.adaptive-portrait .navigation__nav {
  padding: 0;
}

.adaptive-portrait .nav {
  position: fixed;
  left: 0;
  top: calc(6.1rem);
  right: 0;
  bottom: 0;
  /*height: auto;*/
  width: 100%;
  z-index: 10;
  background-color: rgba(28, 28, 25, 0.88);
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: center top;
  -ms-transform-origin: center top;
  transform-origin: center top;
  overflow-y: auto;
}

.adaptive-portrait .nav__list {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  opacity: 0;
  -webkit-transition: 0.3s all 0.3s;
  -o-transition: 0.3s all 0.3s;
  transition: 0.3s all 0.3s;
}

.adaptive-portrait .nav__link {
  width: 100%;
  text-align: left;
  margin: 0;
  padding: calc(1rem) calc(1rem) calc(1rem) 40%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-color: transparent;
  text-shadow: 0 calc(0.2rem) calc(0.2rem) rgba(0, 0, 0, 0.46);
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-height: calc(7rem);
}

.adaptive-portrait .nav__link:not(:nth-last-child(1)) {
  border-bottom: 2px solid rgba(255, 255, 255, 0.22);
}

.adaptive-portrait .nav__link:hover {
  background-color: rgb(32, 31, 31);
}

.adaptive-portrait .nav__ico {
  margin-right: 10px;
  width: calc(6rem);
  height: calc(6rem);
}

.adaptive-portrait .nav__name {
  font-size: calc(2rem);
  line-height: 1.2;
}

.adaptive-portrait .nav__desc {
  font-size: calc(1.3rem);
  line-height: 1.2;
}

.adaptive-portrait.mob-nav-active {
  overflow: hidden;
}

.adaptive-portrait.mob-nav-active .nav {
  opacity: 1;
  visibility: visible;
}

.adaptive-portrait.mob-nav-active .nav__list {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* footer */
.adaptive-portrait .logo_footer,
.adaptive-portrait .banners {
  display: none;
}

@media (min-width: 500px) {
  .logo_pos {
    max-width: none;
  }

  .snews__title {
    font-size: 4.6rem;
  }

  .snews__sub-title {
    font-size: 2.2rem;
  }

  .snews__counter {
    width: 4rem;
    font-size: 1.4rem;
  }

  .snews__arrows {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .stream {
    height: calc(30vw);
    width: calc(100% / 2 - 0.5rem * 2);
    margin: 0 0.5rem;
  }

  .streams {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -0.5rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .scl__gwi {
    font-size: 13vw;
  }

  .scl__name {
    font-size: 2vw;
    margin-top: 2vw;
  }
}

@media (min-width: 600px) {
  .page {
    background: #030000 url(../images/bg/bg-desktop.jpg) no-repeat center top;
    background: var(--background-body) url(../images/bg/bg-desktop.jpg)
      no-repeat center top;
  }

  .scl__link {
    width: calc(100% / 9 - 0.5rem * 2);
  }

  .scl__icon {
    height: 4vw;
  }

  .scl__gwi {
    font-size: 3vw;
  }

  .scl__name {
    font-size: 1.1vw;
    margin-top: 0.5rem;
  }
}

@media (-ms-high-contrast: active) and (min-width: 650px),
  (-ms-high-contrast: none) and (min-width: 650px) {
  .server {
    margin: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@media (min-width: 650px) {
  .page {
    padding-top: 0;
  }

  [data-section='navigation'] {
    position: relative;
  }

  .server {
    margin: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  [data-section='servers'] {
    max-height: 20rem;
  }

  .footer-hide [data-section='servers'] {
    max-height: 24rem;
  }

  .servers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .servers__btn-box {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin: 2rem auto;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  [data-container='footer'] {
    display: block;
  }

  .footer__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .footer__info {
    margin: 1rem 0 1rem 1.5rem;
    display: none;
  }
}

@media (min-width: 700px) {
  .stream {
    height: 12rem;
    margin-top: 0;
    width: calc(100% / 3 - 0.5rem * 2);
    margin: 0 0.5rem;
  }

  .stream:nth-child(4) {
    display: none;
  }

  .streams {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

@media (min-width: 800px) {
  .ps__img_paypal {
    width: 7.3rem;
    height: 2rem;
  }

  .ps__img_g2a {
    width: 13.1rem;
    height: 2.7rem;
  }

  .ps__img_paymentwall {
    width: 16.4rem;
    height: 2.1rem;
  }

  .ps__img_freekassa {
    width: 14.5rem;
    height: 2.8rem;
  }

  .ps__img_payop {
    width: 6.4rem;
    height: 2.1rem;
  }

  .scl__link {
    width: calc(100% / 9 - 0.5rem * 2);
  }

  .scl__icon {
    height: 4rem;
  }

  .scl__gwi {
    font-size: 3rem;
  }

  .scl__name {
    font-size: 1rem;
  }
}

@media (min-width: 1000px) {
  .scl__link {
    width: calc(100% / 9 - 0.52rem * 2);
  }

  .scl__icon {
    height: 6rem;
  }

  .scl__gwi {
    font-size: 4.5rem;
  }
}

@media (min-width: 1000px) and (min-height: 950px) and (orientation: portrait) {
  html {
    font-size: calc(0.4vw + 0.5vh);
  }

  .mid__box {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .gw-burger {
    display: block;
  }
}

@media (min-width: 1000px) and (min-height: 950px) and (orientation: portrait) and (-ms-high-contrast: active),
  (min-width: 1050px) and (orientation: landscape) and (-ms-high-contrast: none) {
  .mid__item {
    width: 100%;
  }
  .mid__box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (min-width: 1000px) and (orientation: portrait) {
  .scl__icon {
    height: 4rem;
  }

  .scl__gwi {
    font-size: 3rem;
  }

  .scl__name {
    font-size: 0.8rem;
  }
}

@media (min-width: 1050px) {
  .mid:after {
    content: '';
  }

  .mid:after {
    width: 158.4rem;
    left: calc(50% - 157rem / 2);
    top: -1rem;
  }

  .stream {
    width: calc(100% / 5 - 0.5rem * 2);
    margin: 0 0.5rem;
  }

  .stream:nth-child(4) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .stream:nth-child(5) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 1050px) and (orientation: landscape) {
  .mid__box {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.4rem;
    gap: 0.4rem;
  }

  .mid__slider {
    margin-right: 0.3rem;
    margin-bottom: 0;
  }

  .mid__content {
    background: transparent;
    margin-left: 0.3rem;
  }
}

@media (min-width: 1050px) and (orientation: landscape) and (-ms-high-contrast: active),
  (min-width: 1050px) and (orientation: landscape) and (-ms-high-contrast: none) {
  .mid__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-grid-columns: none;
    grid-template-columns: none;
  }

  .mid__item {
    width: calc(100% / 2 - 0.2rem * 2);
    margin: 0 0.2rem;
  }
}

@media (min-width: 1100px) {
  .bar {
    right: 5rem;
  }

  .logo_pos {
    left: 5rem;
  }

  .servers {
    height: 16rem;
  }

  .footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .footer__f-nav:nth-child(1) {
    padding-right: 4rem;
  }
}

@media (min-width: 1100px) and (orientation: landscape) {
  .footer__info {
    display: block;
  }
}

@media (min-width: 1100px) and (orientation: landscape) and (-ms-high-contrast: active),
  (min-width: 1100px) and (orientation: landscape) and (-ms-high-contrast: none) {
  body {
    height: 100%;
  }

  .page {
    height: 100%;
  }
}

@media (min-width: 1200px) and (orientation: landscape) {
  .scl:after {
    content: '';
  }
}

@media (min-width: 1300px) {
  .logo_footer {
    display: inline-block;
  }

  .nav__desc {
    font-size: calc(1.1rem);
    margin-top: calc(0.1rem);
  }
}

@media (min-width: 1300px) and (min-height: 950px) and (orientation: landscape) {
  html {
    font-size: calc(0.25vw + 0.5vh);
  }
}

@media (min-width: 1450px) {
  .banners {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 1600px) {
  .mid:after {
    width: 159rem;
    left: calc(50% - 158rem / 2);
    top: -0.8rem;
  }

  .scl:after {
    width: 117rem;
    left: calc(50% - 58.5rem);
  }
}

@media (min-width: 1800px) {
  .mid:after {
    width: 161.5rem;
    left: calc(50% - 160rem / 2);
    top: -0.5rem;
  }
}

@media (min-width: 1921px) {
  .page {
    background: #030000 url(../images/bg/bg-large.jpg) no-repeat center top;
    background: var(--background-body) url(../images/bg/bg-large.jpg) no-repeat
      center top;
    background-size: cover;
  }
}

@media (min-width: 2001px) {
  .mid:after {
    width: calc(161.5rem + 0.5rem);
    height: calc(67rem + 0.5rem);
    left: calc(calc(50% - 161rem / 2));
    top: -0.7rem;
  }
}

@media (min-width: 2201px) {
  .mid:after {
    width: calc(161.5rem + 1rem);
    height: calc(67rem + 1rem);
  }
}

@media (min-width: 2500px) {
  .scl:after {
    width: 119.8rem;
    height: 14.5rem;
    position: absolute;
    top: calc(100% - 4.2rem);
    left: calc(50% - 59.8rem);
  }
}

@media (min-width: 2601px) {
  .mid {
    padding-top: 1rem;
  }

  .mid:after {
    width: calc(161.5rem + 1.5rem);
    height: calc(67rem + 1.5rem);
    left: calc(50% - 161.5rem / 2);
  }
}

@media (min-width: 3001px) {
  .mid:after {
    width: calc(161.5rem + 2.8rem);
    height: calc(67rem + 1.8rem);
    left: calc(50% - 163rem / 2);
  }
}

@media (min-width: 3501px) {
  .mid {
    padding-top: 1.2rem;
  }

  .mid:after {
    width: calc(161.5rem + 3.2rem);
    height: calc(67rem + 2.2rem);
    left: calc(50% - 163.5rem / 2);
  }
}

@media (max-width: 1299px),
  (min-width: 1000px) and (min-height: 950px) and (orientation: portrait) {
  /* navigation */
  [data-container='navigation'] {
    max-width: none;
    width: 100%;
    padding: 0;
  }

  .navigation {
    display: block;
  }

  .navigation__nav {
    padding: 0;
  }

  .nav {
    position: fixed;
    left: 0;
    top: calc(6.1rem);
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
    background-color: rgba(28, 28, 25, 0.88);
    -webkit-transition: 0.3s all;
    -o-transition: 0.3s all;
    transition: 0.3s all;
    opacity: 0;
    visibility: hidden;
    -webkit-transform-origin: center top;
    -ms-transform-origin: center top;
    transform-origin: center top;
    overflow-y: auto;
    height: 100%;
    max-height: calc(100vh - 6.1rem);
  }

  .nav__list {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
    -webkit-transition: 0.3s all 0.3s;
    -o-transition: 0.3s all 0.3s;
    transition: 0.3s all 0.3s;
  }

  .nav__link {
    width: 100%;
    text-align: left;
    margin: 0;
    padding: calc(1rem) calc(1rem) calc(1rem) 40%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-color: transparent;
    text-shadow: 0 calc(0.2rem) calc(0.2rem) rgba(0, 0, 0, 0.46);
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-height: calc(7rem);
  }

  .nav__link:not(:nth-last-child(1)) {
    border-bottom: 2px solid rgba(255, 255, 255, 0.22);
  }

  .nav__link:hover {
    background-color: rgb(32, 31, 31);
  }

  .nav__ico {
    margin-right: 10px;
    width: calc(6rem);
    height: calc(6rem);
  }

  .nav__name {
    font-size: calc(2rem);
    line-height: 1.2;
  }

  .nav__desc {
    font-size: calc(1.3rem);
    line-height: 1.2;
  }

  .mob-nav-active {
    overflow: hidden;
  }

  .mob-nav-active .nav {
    opacity: 1;
    visibility: visible;
  }

  .mob-nav-active .nav__list {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@media (min-width: 1100px) and (max-width: 1299px) and (max-height: 999px) and (orientation: landscape) {
  html {
    font-size: calc(0.4vw + 0.45vh);
  }
}

@media (min-width: 1300px) and (max-height: 949px) and (orientation: landscape) {
  html {
    font-size: calc(0.25vw + 0.55vh);
  }
}

@media (min-width: 1300px) and (max-height: 929px) and (orientation: landscape) {
  html {
    font-size: calc(0.25vw + 0.5vh);
  }
}

@media (min-width: 1300px) and (max-width: 1449px) and (max-height: 999px) and (orientation: landscape) {
  html {
    font-size: calc(0.3vw + 0.5vh);
  }
}

@media (min-width: 1100px) and (max-width: 1449px) and (max-height: 849px) and (orientation: landscape) {
  html {
    font-size: calc(0.2vw + 0.63vh);
  }
}

@media (max-width: 1299px) {
  .gw-burger {
    display: block;
  }
}

@media (max-width: 1299px) and (min-width: 1000px) and (orientation: portrait) {
  .nav__link {
    padding-left: 30%;
  }

  .nav__ico {
    width: calc(10rem);
    height: calc(10rem);
  }

  .nav__name {
    font-size: calc(3em);
  }

  .nav__desc {
    font-size: calc(2em);
  }
}

@media (max-width: 899px) {
  .nav__link {
    padding-left: 35%;
  }
}

@media (-ms-high-contrast: active) and (max-width: 649px),
  (-ms-high-contrast: none) and (max-width: 649px) {
  .server {
    margin: 25rem auto;
  }
}

@media (max-width: 649px) {
  .nav__link {
    padding-left: 30%;
  }
}

@media (max-width: 499px) {
  .nav__link {
    padding-left: 10%;
  }
}

/*@media (max-width: 460px) {*/
/*  .preload {*/
/*    display: none;*/
/*  }*/
/*}*/

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .lang__item {
    width: calc(100% / 3 - 1px);
  }

  .lang__hint .hint__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  [data-container='navigation'] {
    max-width: 124rem;
    min-width: 470px;
    min-width: var(--min-width);
  }

  .navigation {
    padding-top: 0.4rem;
  }

  .server__life {
    display: none;
  }

  .servers__btn-box {
    width: auto;
  }

  .snews__slide {
    padding-top: 7rem;
  }

  .item {
    padding-top: 1.4rem;
  }

  .item__content {
    max-width: calc(100% - 8.2rem - 10.7rem - 0.5rem - 1rem);
  }

  .under__ps {
    padding-top: 1.5rem;
  }

  .modal {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .bar {
    margin-top: -1.7rem;
  }

  .arrows__arrow,
  .arrow {
    width: 2.3rem;
    height: 2.7rem;
  }
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .tabs__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .tabs__btn {
    width: calc(100% / 4);
  }
}
